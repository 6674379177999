import React, { Component } from 'react'
import ApiActions from '../../actions/ApiActions';
import moment from "moment";
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import BrandSearch from './Tools/BrandSearch';
import BrandManagerSearch from './Tools/BrandManagerSearch';
import Switch from "react-switch";
import FrappButton from '../utilities/FrappButton';
import { SingleDatePicker } from "react-dates";
import { ToastContainer, toast } from "react-toastify";
import Checkbox from 'react-bootstrap/lib/Checkbox';
import FrappModal from "../utilities/FrappModal";
import capitalise from "../../helper/Capitalise";
import WorkingHours from '../containers/WorkingHours'
import Outcome from '../containers/Outcome'
import LeadDistribution from '../containers/LeadDistribution';
import constants from '../../util/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Dropdown from 'react-multilevel-dropdown';
import CustomTooltip from '../utilities/ToolTip';
import { InfoIcon } from '../../assets/images';

class CreateTeleProject extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            formData: this.props.location.state.formData || {
                title: "",
                description: "",
                brand: "",
                mainGoal: [""],
                   brandSelected: "",
            brandname: "",
                manager: "",
                // faqs:[],
                scriptDocuments:[
                            {title:"Script",
                            url:""}
                        ],
                        audioFiles:[
                            {title:"Mock Audio",
                            url:""}
                        ],
                trainingVideos:[{
                        title: '',
                        url: ''
                }],
                //target: "fixed",
                brandmanager: [],
                teamlead: [],

                reward: 0,
                //commitHours: 0,
                audioClip: "",

                // Template for Standard Outcomes
                outcomes: constants.standardOutcomes,
                payout: "",
                isWhitelisted: false,
                isDialout: false,
                hasAllocatedDialing: false,
                dialer:'',
                startDate: "",
                endDate: "",
                callingAccount: 'getfrapp1',
                callingNumber: 'phone1',
                category: '',
                subCategory: ''
            },
            brandmanagers: [],
            brandmanagerslist: [],
            projectManagers: [],
            coaches: [],
            teamleads: [],
            teamleadslist: [],
            exotelAccount: [],
            bm: false,
            unpublishScript:false,
            sendForm:false,
            workTime: {
                startTime: '9:00',
                endTime: '21:00'
            },
            leadDistribution: {"style": "roundrobin"},
            exotelAccountError: false,
            coachError: false,
            teamLeadError: false,
            projectManagerError: false,
            brandError: false,
            brandManagerError: false,
            categorySubCategoryError: false,
            trueCallerLabelError: false,
            isRewardAboveLimit: false,
            isEncryptionDisabled: false,
            isPredictiveDisabled: false,
            isExotelAccountFieldDisabled: false,
            poolData: '',
            updatedLeadDistributionData: {
                "style": "roundrobin"
            },
            outcomeScope: 0
        }
    }
    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            let teleproject = this.props.location.state.formData;
            this.setState({updateBtn:this.props.location.state.updateBtn})
            let brandmanagers = this.state.brandmanagers;
            let brandManagerFromProp = this.props.location.state.brandmanagers;
            let teamleads = this.state.teamleads;
            ApiActions.getProjectManager().then(resp => {
                this.setState({
                    projectManagers: resp
                })
            })
            ApiActions.getUsersBasedOnRole('coach').then(resp => {
                this.setState({
                    coaches: resp
                })
            })

            // Object.keys(each).some(i => { return i.startsWith('phone') })
            ApiActions.getExotelAccounts().then(resp=>{
                let result = [...resp[0].exotelData].map(each => {
                    const {sid, account} = each
                    const phoneKeyNames = Object.keys(each).filter(function (propertyName) {
                        return propertyName.indexOf("phone") === 0;
                    })
                    let createObjDynamically = [...phoneKeyNames].map(element => {
                        return {'number':element,...each[element]}
                    });
                    return{
                        account,
                        id: sid,
                        [sid]: createObjDynamically,
                    }
                })
                this.setState({exotelAccount: result})
            })

            if (this.props.match.params.id) {
                ApiActions.getTeleProject(this.props.match.params.id).then(resp => {
                    if(!resp.leadDistribution){
                        resp.leadDistribution = {
                             "style": "roundrobin"
                        }
                        this.setState({updatedLeadDistributionData: {
                            "style": "roundrobin"
                        }})
                    }else{
                        this.setState({
                            updatedLeadDistributionData: resp.leadDistribution
                        })
                    }
                    if(!resp.workTime){
                        resp.workTime = {
                            startTime: '9:00:00',
                            endTime: '21:00:00'  
                        }
                    }
                    if(!resp.category || !resp.subCategory){
                        resp.category = ''
                        resp.subCategory = ''
                    }

                    if(!resp.trainingVideos || resp.trainingVideos.length <= 0){
                        resp.trainingVideos = [
                            {
                                title: '',
                                url: ''
                            }
                        ]
                    }
                    if(resp.outcomes){
                        resp.outcomes.map(outcome => {
                            if(!outcome.isFollowup){
                                // add the followup for the outcomes if it does not exist
                                outcome.isFollowup = false
                            }
                            if(outcome.allowed){
                                this.setState({redialValue: outcome.allowed})
                            }
                            return outcome
                        })
                    }
                    this.setState({ formData: resp })
                    this.setState({ 
                        createProject: true, 
                        updateBtn: true, 
                        brandSelected: resp.brand, 
                        manager: resp.manager 
                    })
                    if(resp.brand){
                        ApiActions.getBrandManagerById(resp.brand).then(resp => {
                            this.setState({brandmanagerslist: resp})
                          })
                    }
                    if (resp.brandmanager) {
                        resp.brandmanager.map(manager => {
                            ApiActions.getBrandManagerForTring(manager).then(resp2 => {
                                brandmanagers.push(resp2)
                                this.setState({ brandmanagers })
                            })

                        }, this.setState({ bm: true }))
                    }

                    ApiActions.getUsersBasedOnRole('team_lead').then(teamleadslist => {
                        this.setState({
                            teamleadslist: teamleadslist
                        })
                    }).then(() => {
                      resp.teamlead && resp.teamlead.map(lead => {
                            teamleads.push(this.state.teamleadslist.find((obj) => obj.id === lead))
                            this.setState({ teamleads })
                        })
                    })

                    ApiActions.getBrandName(resp.brand).then(resp => {
                        if(resp.length>0)
                        this.setState({ brandname: resp[0].name })
                        else
                        this.setState({brandname:""})
                    })
                })
                this.setState({isEncryptionDisabled: true, isPredictiveDisabled: true})
                this.setState({
                    isExotelAccountFieldDisabled: true
                })
                ApiActions.getActiveExophones({
                    teleproject: this.props.match.params.id
                }).then(resp => {
                    const {poolSet: {account, pool}} = resp
                    const [first] = pool
                    const {numbers, truecallerIdentityLabelId} = first
                    const exophone = numbers && numbers.length > 0 ? numbers[0] : {}

                    this.setState({
                        poolData: {
                            account,
                            primary: exophone && exophone.primary || 'NA',
                            outgoing: exophone && exophone.outgoing || 'NA',
                            truecallerIdentityLabelId : truecallerIdentityLabelId ? truecallerIdentityLabelId : undefined
                        }
                    })
                }).catch(err => {
                    console.error(err)
                    this.setState({
                        poolData: {
                            account: 'NA',
                            primary: 'NA',
                            outgoing: 'NA',
                            truecallerIdentityLabelId : 'NA'
                        }
                    })
                })
            }else{
                const workTime = {
                    startTime: '9:00',
                    endTime: '21:00'
                }
                this.setState({updatedLeadDistributionData: {
                    "style": "roundrobin"
                }})
                let callingAccount = ''
                let callingNumber = ''
                let leadDistribution = {"style": "roundrobin"}
                this.props.location.state.formData = {...this.props.location.state.formData, workTime, callingAccount, callingNumber, leadDistribution}

                ApiActions.getUsersBasedOnRole('team_lead').then(resp => {
                    this.setState({
                        teamleadslist: resp
                    })
                })
            }

            this.setState({
                // formData: this.props.location.state.formData,
                createProject: this.props.location.state.createProject,
                updateBtn: this.props.location.state.updateBtn,
                brandSelected: this.props.location.state.formData.brand,
                brandmanagers: this.props.location.state.formData.brandmanagers,
                teamleads: this.props.location.state.formData.teamleads

            })
            if (teleproject.brandmanager) {

                teleproject.brandmanager.map(manager => {
                    ApiActions.getBrandManagerForTring(manager).then(resp => {
                        brandManagerFromProp.push(resp)
                        this.setState({ brandmanagers: brandManagerFromProp })

                    })

                }, this.setState({ bm: true }))
            }

            this.setState(this.props.history.location.state);
        }

    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <div >
                <h1>{this.renderForm()}</h1>
                <ToastContainer draggable={true} />
            </div>
        )
    }

    updateFormData = (data) => {
        let { formData } = this.state;
        let updatedFormData = {...formData, ...data}
        this.setState({formData: updatedFormData})
    }

    bmArray = (e) => {
        let { formData, brandmanagerslist } = this.state;
        let tempArray = formData.brandmanager
        tempArray.push(e.target.value)
        formData.brandmanager = tempArray
        this.setState({bm:true})

        const brandmanagersSelected = brandmanagerslist.map(i => {
            if(tempArray.includes(i.id)) return i
        }).filter(Boolean)
        this.setState({ brandmanagers: brandmanagersSelected })
        this.setState({brandManagerError: false})
    }

    tlArray = (e) => {
        let { formData, teamleadslist } = this.state;
        let tempArray = formData.teamlead || []
        tempArray.push(e.target.value)
        formData.teamlead = tempArray

        const teamLeadsSelected = teamleadslist.map(i => {
            if(tempArray.includes(i.id)) return i
        }).filter(Boolean)
        this.setState({ teamleads: teamLeadsSelected , teamLeadError: false})
    }


    removeBrandManager = (e) => {
        const {brandmanagers, formData} = this.state
        const updateBrandManagers = brandmanagers && brandmanagers.filter(manager => manager.id !== e.id)
        this.setState({brandmanagers: updateBrandManagers})
        const tempArray = formData.brandmanager
        formData.brandmanager = tempArray.filter(i => i !== e.id)
    }

    removeTeamLead = (e) => {
        const {teamleads, formData} = this.state
        const updateTeamLeads = teamleads && teamleads.filter(manager => manager.id !== e.id)
        this.setState({teamleads: updateTeamLeads})
        const tempArray = formData.teamlead
        formData.teamlead = tempArray.filter(i => i !== e.id)
    }

    renderForm = () => {
        let { formData, isExotelAccountFieldDisabled } = this.state;
        let data = this.state;
        const triggerStartDatePicker = () => {
            this.setState({ startDateFocussed: true });
        };
        const triggerEndDatePicker = () => {
            this.setState({ endDateFocussed: true });
        };
        var startDate = formData&&formData.startDate
            ? moment.utc(new Date(formData.startDate))
            : null;
       
        var endDate = formData&&formData.endDate
            ? moment.utc(new Date(formData.endDate))
            : null;
       
       let coachFound = this.state.coaches.find(item => item.id == this.state.formData.coach)

       const renderStartDate = () => {
        return <div
        name="startDate"
        onClick={() => this.props.match.params.id ? null : triggerStartDatePicker()}
        className="campaign-application-end-date-input project_date_selection"
        disabled={this.props.match.params.id ? true : false}
    >
        
        <SingleDatePicker
            noBorder={true}
            id="startDate"
            placeholder='Select a date'
            date={startDate}
            focused={this.state.startDateFocussed}
            //   isOutsideRange={this.checkIsOutsideRange}
            onFocusChange={focused => {
                this.setState({ startDateFocussed: focused.focused });
            }}
            onDateChange={(e) => this.setFormDate(e, 0)}
            numberOfMonths={1}
            disabled={this.props.match.params.id ? "startDate" : false}
        />
        </div>
    }
    
       return (
            <div className="form-wrapper">
                <div className="heading">
                    {this.renderNotifPopup()}
                    <div className="arrow" onClick={() => this.handleBack()}>  
                      <img src={require("../../assets/images/back-arrow.png")} /> 
                     <div className="back">Back</div>
                     </div>
                    <div><h4>{formData.title}</h4></div><h5>Step 1: Add Details </h5> </div>

                <form onSubmit={this.submitForm}>
                    <div id="container-1" >
                        <div className="col-md-6">
                            <FormGroup>
                                <ControlLabel>Project Title</ControlLabel>
                                <FormControl
                                    placeholder="Enter title"
                                    required
                                    name="title"
                                    value={formData.title}
                                    onChange={this.setFormData}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Description</ControlLabel>
                                <FormControl
                                    required
                                    placeholder="Enter Description"
                                    name="description"
                                    value={formData.description}
                                    onChange={this.setFormData}
                                />
                            </FormGroup>

                            <FormGroup>
                                <ControlLabel>Main Goal</ControlLabel>

                                {formData && formData.mainGoal && formData.mainGoal.map((mainGoal, i) => {
                                    return this.mainGoal(mainGoal, i)

                                })}

                            </FormGroup>

                            <FormGroup controlId="formControlsSelect">
                            <ControlLabel>Project category & subcategory</ControlLabel>
                            <Dropdown
                            title={formData.category ? <span>{formData.category} - {formData.subCategory}</span>:'Select project category'}
                            position="right"
                            wrapperClassName='nested-menu'
                            buttonClassName='nested-menu-button'
                            id='submenu'
                            >
                            {
                                constants.projectCategories.map(catg => {
                                    return <Dropdown.Item><div className='option'>
                                        <span>{catg.category}</span>
                                        <FontAwesomeIcon icon={faChevronRight}/></div>
                                    <Dropdown.Submenu position="right">
                                    {
                                        catg.subcategories.map(subcatg => {
                                        return <Dropdown.Item onClick={(e) => {
                                            formData.category = catg.category
                                            formData.subCategory = subcatg
                                            this.setState({ formData })
                                            if(formData.category && formData.subCategory){
                                                this.setState({categorySubCategoryError: false})
                                            }
                                            document.getElementById("submenu").click();
                                        }}
                                            ><span className='truncate-label'>{subcatg}</span></Dropdown.Item>
                                        })
                                    }
                                        </Dropdown.Submenu>
                                    </Dropdown.Item>
                                })
                            }
                            </Dropdown>
                            </FormGroup>
                            {this.state.categorySubCategoryError ? <span className='form-error-msg'>Please add the category & sub category</span> : null}

                            <FormGroup>
                                <ControlLabel>Brand</ControlLabel>

                                <h5 style={{textAlign:"left",fontSize:"16px"}}>  {this.state.brandname}</h5>

                                <BrandSearch onBrandSelect={this.onBrandSelect} ref={(c) => this.brandsearch = c} />

                            </FormGroup>

                            {this.state.brandError ? <span className='form-error-msg'>Please select brand</span> : null}

                            {/* <FormGroup>
                                <ControlLabel>Brand Manager</ControlLabel>
                               
                                {this.state.brandmanagers.map(datum => {
                                    return <h5 id="brandmanager">  {datum.firstname}</h5>
                                })}

                                {this.state.bm ? <BrandManagerSearch brand={this.state.brandSelected} bmArray={this.bmArray} brandmanagers={this.state.brandmanagers} 
                                removeBrandManager = {this.removeBrandManager}/> : null}

                            </FormGroup> */}

                            <FormGroup controlId="formControlsSelect">
                                <ControlLabel>Brand Manager</ControlLabel>
                                <div className='selected-brand-managers'>
                                {this.state.brandmanagers && this.state.brandmanagers.sort((a,b) => a.firstname.localeCompare(b.firstname)).map(datum => {
                                    return <div className='brand-manager-row'
                                    ><span className='brand-manager'>{datum.firstname} </span>
                                        <img src={require("../../assets/images/del-icon-taskprops.svg")}
                                        className='red-delete-img'
                                        onClick={() => this.removeBrandManager(datum)} />
                                    </div>
                                })}
                                </div>

                                <FormControl 
                                    componentClass="select" 
                                    placeholder="select" 
                                    onChange={this.bmArray}
                                >
                                    <option value="select" hidden>Select brand managers</option>
                                    {/* {!this.state.brandname && <option>Select brand to get brand managers</option>} */}
                                    {
                                        this.state.brandmanagerslist && this.state.brandmanagerslist.map((item)=>{
                                            return this.state.formData.brandmanager && this.state.formData.brandmanager.length <= 0 ?
                                            <option key={item.id} value={item.id}>{item.firstname}</option> :
                                            this.state.formData.brandmanager.includes(item.id) ? null :
                                            <option key={item.id} value={item.id}>{item.firstname}</option>
                                        })
                                    }
                                </FormControl>
                            </FormGroup>
                            {this.state.brandManagerError ? <span className='form-error-msg'>Please select brand manager</span> : null}

                            {/* <div className="grey-box-wrapper p-0">
                            <div className='dotted-border p-4'> */}
                            <FormGroup>
                                <ControlLabel> Documents</ControlLabel>
                                <div className="grey-box-wrapper p-0">
                                <div className='dotted-border p-4'>
                                    {formData && formData.scriptDocuments && formData.scriptDocuments.map((script, i) => {
                                        return this.scriptField(script, i, formData)

                                    })}
                                    <div className="btn-wrapper">
                                        <FrappButton className="add-btn" handler={() => this.addScript()}> +Add Document </FrappButton>

                                    </div>
                                </div>
                                </div>
                            </FormGroup>
                             <FormGroup>
                                <ControlLabel> Audio</ControlLabel>
                                <div className="grey-box-wrapper p-0">
                                <div className='dotted-border p-4'>
                                    {formData && formData.audioFiles && formData.audioFiles.map((audio, i) => {
                                        return this.audioField(audio, i, formData)

                                    })}
                                    <div className="btn-wrapper">
                                        <FrappButton className="add-btn" handler={() => this.addAudio()}> +Add Audio </FrappButton>

                                    </div>
                                </div>
                                </div>

                            </FormGroup>

                            <FormGroup>
                                <ControlLabel> Training Video</ControlLabel>
                                <div className="grey-box-wrapper p-0">
                                <div className='dotted-border p-4'>
                                    {
                                    
                                    formData && formData.trainingVideos && formData.trainingVideos.map((video, i) => {
                                        return this.videoField(video, i, formData)

                                    })
                                    }
                                    <div className="btn-wrapper">
                                        <FrappButton className="add-btn" handler={() => this.addTrainingVideo()}> +Add training video </FrappButton>
                                    </div>
                                </div>
                                </div>
                            </FormGroup>
                            {/* <FormGroup>
                                <ControlLabel>Select target</ControlLabel>
                                <FormControl
                                    required
                                    placeholder="Select Target"
                                    name="target"
                                    value={formData.target}
                                    onChange={this.setFormData}
                                    componentClass="select"
                                >
                                    <option value="fixed">Fixed</option>
                                    <option value="flexible">Flexible</option>
                                </FormControl>
                            </FormGroup> */}
                            <div style={{display: 'flex', gap: '5%'}}>
                            <FormGroup>
                                <ControlLabel>Select Start Date</ControlLabel>


                         
                                {
                                    this.props.match.params.id ?
                                    <CustomTooltip placement={'bottom'} description={'Start date is locked once created'}
                                    component={renderStartDate()}>
                                    </CustomTooltip>
                                    :
                                    renderStartDate()
                                }
                            </FormGroup>

                            <FormGroup>
                                <ControlLabel>Select End Date</ControlLabel>
                                <div
                                    name="endDate"
                                    onClick={() => triggerEndDatePicker()}
                                    className="campaign-application-end-date-input"
                                >
                                    <SingleDatePicker
                                        noBorder={true}
                                        id="Date"

                                        date={endDate}
                                        placeholder='Select a date'
                                        focused={this.state.endDateFocussed}
                                        //   isOutsideRange={this.checkIsOutsideRange}
                                        onFocusChange={focused => {
                                            this.setState({ endDateFocussed: focused.focused });
                                        }}
                                        onDateChange={(e) => this.setFormDate(e, 1)}
                                        numberOfMonths={1}
                                    // displayFormat={this.dateDisplayFormat()}
                                    />
                                    {/* {endDate
                                        ? <h5>{endDate.format("ll")}</h5>
                                        : <h5>Select a Date</h5>} */}
                                </div>
                            </FormGroup>
                            </div>
                            <FormGroup>
                            <ControlLabel> Time slots</ControlLabel>
                            <div className="grey-box-wrapper p-0">
                            <div className='dotted-border p-4'>
                            {formData.workTime ? <WorkingHours formData={formData} getWorkTiming = {this.getWorkTiming} removeSecondTimeSlot={this.removeSecondTimeSlot}/> : 
                            null}
                            {formData.workTime && formData.workTime.startTime2 && formData.workTime.endTime2
                            ? null : <div className="btn-wrapper">
                                <FrappButton className="add-btn" handler={() => this.addTimeSlot()}> +Add another slot </FrappButton>
                            </div> }
                            </div>
                            </div>
                            </FormGroup>
                          
                          <div style={{marginTop: '15%'}}>
                          <div className="btn-wrapper" id="crud">
                                <FrappButton className="submit" id="new-btn" type="submit" ref={(c) => this.submitBtn = c}>
                                    {this.state.updateBtn ? "Save" : "Save"}
                                </FrappButton>
                             {this.state.formData.id?     <FrappButton className="submit" id="new-btn-2" 
                           ref={(c) => this.nextBtn = c} 
                           handler={() =>  this.props.history.push({
                        // pathname: `/additional-data/${formData.id}`,
                        // pathname: `/add-tasks/${formData.id}`,
                        pathname: `/add-quiz/${formData.id}`,
                        state: {
                            formData,
                            data
                        }
                    })}> Skip</FrappButton>:null}
                          </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                            {/* <FormGroup>
                                <ControlLabel>Hours</ControlLabel>
                                <FormControl
                                    required
                                    placeholder="Enter Commitment Hours"
                                    name="commitHours"
                                    value={formData.commitHours}
                                    onChange={this.setFormData}
                                />
                            </FormGroup> */}

                            {/* <FormGroup>
                            <ControlLabel>Select time</ControlLabel>
                            {formData.workTime ? <WorkingHours formData={formData} getWorkTiming = {this.getWorkTiming}/> : 
                            null}
                            </FormGroup> */}
                      
                            <FormGroup>
                                <ControlLabel>Payout</ControlLabel>
                                <FormControl
                                    required
                                    placeholder="Enter Payout"
                                    name="payout"
                                    value={formData.payout}
                                    onChange={this.setFormData}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Reward</ControlLabel>
                                <FormControl
                                    required
                                    placeholder="Enter Reward"
                                    name="reward"
                                    value={formData.reward}
                                    onChange={this.setFormData}
                                />
                            </FormGroup>
                            {this.state.isRewardAboveLimit ? <span className='form-error-msg'>Please enter reward under the limit 0-5</span> : null}
                            {/* <FormGroup>
                                <ControlLabel>Manager</ControlLabel>
                                <FormControl
                                    required
                                    placeholder="Enter Manager Name"
                                    name="manager"
                                    value={formData.manager}
                                    onChange={this.setFormData}
                                />
                            </FormGroup> */}
                            <FormGroup controlId="formControlsSelect">
                                <ControlLabel>Project Manager</ControlLabel>
                                <h5 style={{float: "left"}}>
                                    {this.state.formData.manager && this.state.formData.manager }
                                </h5>

                                <FormControl 
                                    componentClass="select" 
                                    placeholder="select" 
                                    onChange={this.setProjectManager}
                                >
                                    <option value="select">select</option>
                                    {
                                        this.state.projectManagers.map((item)=>{
                                            return <option key={item.id} value={item.id}>{item.firstname} {item.lastname}</option>
                                        })
                                    }
                                </FormControl>
                            </FormGroup>
                            {this.state.projectManagerError ? <span className='form-error-msg'>Please select project manager</span> : null}

                            <FormGroup controlId="formControlsSelect">
                                <ControlLabel>Coach</ControlLabel>
                                {
                                    this.state.coaches && this.state.coaches.length > 0 && this.state.formData.coach ? 
                                    <h5 style={{float: "left"}}>
                                        {
                                            typeof coachFound !== 'undefined' ? <span>{coachFound && coachFound.firstname}&nbsp; - &nbsp;{coachFound && coachFound.email}</span> : null
                                        }
                                    </h5>
                                    :
                                    null
                                }
                                
                                <FormControl 
                                    componentClass="select" 
                                    placeholder="select" 
                                    onChange={this.setCoach}
                                >
                                    <option value="select">select</option>
                                    {
                                        this.state.coaches.map((item)=>{
                                            return <option key={item.id} value={item.id}>{item.firstname} {item.lastname}&nbsp;  -  &nbsp;{item.email}</option>
                                        })
                                    }
                                </FormControl>
                            </FormGroup>
                            {this.state.coachError ? <span className='form-error-msg'>Please select coach</span> : null}
  
                            <FormGroup controlId="formControlsSelect">
                                <ControlLabel>Team Lead</ControlLabel>
                                <div className='selected-brand-managers'>
                                {this.state.teamleads && this.state.teamleads.sort((a,b) => a.firstname.localeCompare(b.firstname)).map(datum => {
                                    return <div className='brand-manager-row'
                                    ><span className='brand-manager'>{datum && datum.firstname} {datum && datum.lastname}</span>
                                        <img src={require("../../assets/images/del-icon-taskprops.svg")}
                                        className='red-delete-img'
                                        onClick={() => this.removeTeamLead(datum)} />
                                    </div>
                                })}
                                </div>

                                <FormControl 
                                    componentClass="select" 
                                    placeholder="select" 
                                    onChange={this.tlArray}
                                >
                                    <option value="select" hidden>Select team leads</option>
                                    {
                                        this.state.teamleadslist && this.state.teamleadslist.map((item)=>{
                                            return this.state.formData.teamlead && this.state.formData.teamlead.length <= 0 ?
                                            <option key={item.id} value={item.id}>{item.firstname} {item.lastname}</option> :
                                            this.state.formData.teamlead && this.state.formData.teamlead.includes(item.id) ? null :
                                            <option key={item.id} value={item.id}>{item.firstname} {item.lastname}</option>
                                        })
                                    }
                                </FormControl>
                            </FormGroup>
                            {this.state.teamLeadError ? <span className='form-error-msg'>Please select team leads</span> : null}


                            <div>
                            <FormGroup>
                                <ControlLabel>Exotel Account</ControlLabel>

                                <FormControl 
                                    componentClass="select" 
                                    placeholder="select" 
                                    onChange={this.setExotelAccount}
                                    value={formData.callingAccount}
                                >
                                    <option value="select" hidden>Select exotel account</option>
                                    <option value="getfrapp1">Domestic non consent</option>
                                    <option value="futwork1">Domestic dnd+</option>
                                    <option value="futwork3">International UAE</option>
                                    <option value="futwork1m">Futwork M</option>
                                    <option value="futwork2m">Futwork2 M</option>
                                    <option value="futwork3m">Futwork3 M</option>
                                    <option value="futwork5m">Futwork5 M</option>
                                    <option value="futwork6">Futwork6</option>
                                    <option value="futwork6m">Futwork6 M</option>
                                    <option value="futwork7m">Futwork7 M</option>
                                    <option value="futwork9m">Futwork9 M</option>
                                    <option value="futwork10m">Futwork10 M</option>
                                    <option value="futwork11m">Futwork11 M</option>
                                </FormControl>
                            </FormGroup>
                            </div>
                            {this.state.exotelAccountError ? <span className='form-error-msg'>Please select the exotel account</span> : null}
                            

                           {this.props.match.params.id && <div className='exotel-details'>
                            <FormGroup>
                                <ControlLabel>Primary number</ControlLabel>
                                <FormControl
                                    required
                                    placeholder="Enter primary number"
                                    name="primary"
                                    disabled
                                    value={this.state.poolData.primary}
                                    // onChange={this.setFormData}
                                />
                            </FormGroup>
                            </div>}
                            <div className='switch-grid'>                    
                            <FormGroup id="dnd-toggle">
                                <ControlLabel>Encryption</ControlLabel>
                                 <Switch onChange={this.setEncryption} checked={formData.isEncrypted}
                                 disabled={this.state.isEncryptionDisabled} className={this.state.isEncryptionDisabled ? 'disabled-switch' : ''}/>
                            </FormGroup>
                             {/* <FormGroup id="dnd-toggle">
                                <ControlLabel>Pause Hiring</ControlLabel>
                                 <Switch onChange={this.setPauseHiring} checked={formData.pauseHiring}/>
                            </FormGroup> */}
                          {/* <FormGroup>
                                <ControlLabel> FAQs</ControlLabel>
                                <div >
                                    {formData && formData.faqs && formData.faqs.map((faq, i) => {
                                        return this.faqfields(faq, i)

                                    })}
                                    <div className="btn-wrapper">
                                        <FrappButton className="add-btn" handler={() => this.addFaq()}> +Add Outcome </FrappButton>

                                    </div>
                                </div>

                            </FormGroup> */}
                             
                            {/* <FormGroup id="dnd-toggle">
                                <ControlLabel>DND +</ControlLabel>
                                 <Switch onChange={this.setDnd} checked={formData.isWhitelisted} disabled={this.state.updateBtn}/>
                            </FormGroup> */}

                            <FormGroup id="dnd-toggle">
                                <ControlLabel>Dialout</ControlLabel>
                                 <Switch onChange={this.setDialout} checked={formData.isDialout} />
                            </FormGroup>

                            {/* <Language allLanguage={formData.languages && formData.languages.length > 0 ? formData.languages : []} updateFormData={e => this.updateFormData(e)}/> */}
                            {/* {
                            formData.languages ? <Language allLanguage={formData.languages} updateFormData={e => this.updateFormData(e)}/> 
                            : null} */}

                            <FormGroup id="dnd-toggle">
                                <ControlLabel>ALP</ControlLabel>
                                 <Switch onChange={this.setAllocated} checked={formData.hasAllocatedDialing} />
                            </FormGroup>

                            <FormGroup id="dnd-toggle">
                                <ControlLabel>Predictive</ControlLabel>
                                 <Switch onChange={this.setPredictive} checked={formData.dialer} 
                                 disabled={this.state.isPredictiveDisabled} className={this.state.isPredictiveDisabled ? 'disabled-switch' : ''}/>
                            </FormGroup>
                            {this.props.match.params.id && this.state.poolData.primary !== ('' || 'NA') ? 
                            <FormGroup id="truecaller-label-id-toggle">
                                <ControlLabel>Truecaller Label</ControlLabel>
                                 <Switch onChange={this.setTrueCallerLabel} checked={formData.isTruecallerCallReasonActive} />
                            </FormGroup> 
                            : null}
                            </div>
                            {this.state.poolData.primary !== ('' || 'NA') && this.state.trueCallerLabelError ? 
                            <span className='form-error-msg'>The project must be linked with a calling pool with an active dynamic label on Truecaller.</span>
                            : null}
                            <div>
                             <FormGroup id="dnd-toggle">
                                <ControlLabel>Incoming Call</ControlLabel>
                                 <Switch onChange={this.setIncomingCall} checked={formData.isInboundCallEnabled} />
                            </FormGroup>
                            {this.state.isInboundCallToggleTouched ? 
                            <p className='alert-inbound'>
                                <img src={InfoIcon} />Contact the system admin (dialler specialist) to ensure the is mapped to an incoming call flow on the cloud telephony system.project</p> : null}
                            </div>
                            <FormGroup>
                            <ControlLabel> Languages</ControlLabel>
                            <div className="grey-box-wrapper p-0">
                            <div className='dotted-border p-4'>
                            {formData && formData.languages && Array.isArray(formData.languages) && formData.languages.map((lang, i) => {
                                return this.languageField(lang, i, formData)

                            })}
                            <div className="btn-wrapper" style={{justifyContent: 'flex-start'}}>
                                <FrappButton className="add-btn" handler={() => this.addLanguage()}> +Add more </FrappButton>

                            </div>
                            </div>
                            </div>

                            </FormGroup>

                            <FormGroup>
                                <ControlLabel> Outcomes</ControlLabel>
                                {/* <div className="outcomes">
                                    {formData && formData.outcomes && formData.outcomes.map((outcome, i) => {
                                        return this.outcomeField(outcome, i, formData)

                                    })}
                                    <div className="btn-wrapper">
                                        <FrappButton className="add-btn" handler={() => this.addOutcome()}> +Add Outcome </FrappButton>

                                    </div>
                                </div> */}
                                {
                                    formData.outcomes &&
                                    formData.outcomes.length > 0 ? 
                                    <Outcome outcomes={formData.outcomes}
                                    updateOutcome={this.updateOutcome}
                                    outcomeScopeConfig={this.outcomeScopeConfig}
                                    outcomeScope={this.state.outcomeScope}
                                    />
                                : null}

                            </FormGroup>

                            
                            <FormGroup>
                                <ControlLabel>Lead Distribution</ControlLabel>
                                <LeadDistribution leadDistribution={formData.leadDistribution}
                                updateLeadDistribution={this.updateLeadDistribution}/>
                            </FormGroup>

                        </div>

                    </div>
                </form>
            </div >
        )

    }
    renderNotifPopup=()=>{
        return(
                 <FrappModal
                onCloseButton={true}
                className="tele-payment-modal"
                // show={this.state.showCertifyModal}
                ref={c => (this.notifPopup = c)}
                closeModal={() => this.notifPopup.toggle(false)}
            >
               <div className="notif-popup">
                <div className="heading">You changed the outcome</div>
                <div className="text">
                    Changes made in the outcome, make sure you update the script for the project.
                    The script has been unpublished.
                </div>

             < FrappButton  className="ok-btn" handler={() => this.notifPopup.toggle(false)}> OK  </FrappButton>
               </div>
               
            </FrappModal >
        )
    }
   faqfields=(faq,i)=>{
       let { formData } = this.state;
        return (

          <div className="faq-field">
                <FormControl
                    required
                    placeholder="Enter main goal"
                    name="faq"
                    value={faq}
                    onChange={(e) => this.setFormDataFaq(e, faq, i)}
                />
                  <img onClick={()=>this.deleteFaq(i)} src={require("../../assets/images/delete.svg")}/>
          </div>

        )
   }
   deleteFaq=(i)=>{
       let {formData}=this.state;
       let faqs= formData.faqs;
       faqs.splice(i,1)
       this.setState({formData})
   }
    setFormDataFaq=(e,faq,i)=>{
   let { formData } = this.state;
        let changedFaq= faq;
        changedFaq = e.target.value
        formData.faqs[i] = changedFaq
        this.setState({ formData })
    }

    setFormData = (e) => {
        let { formData } = this.state;
        formData[e.target.name] = e.target.value;
        if((formData && formData.reward > 5) || (formData && formData.reward < 0)){
            this.setState({isRewardAboveLimit: true})
        }else{
            this.setState({isRewardAboveLimit: false})
        }
        this.setState({ formData })
    }

    getWorkTiming = (workTime) => {
        let { formData } = this.state;
        formData.workTime = workTime;
        this.setState({ formData })
    }

    setProjectManager = (e) => {
        let { formData } = this.state;
        this.state.projectManagers.forEach(element => {
            if(e.target.value === element.id){
                formData.manager = `${element.firstname} - ${element.email}`
                this.setState({ formData })
            }
        });
        this.setState({projectManagerError: false})
    }

    setCoach = (e) => {
        let { formData } = this.state;
        this.state.coaches.forEach(element => {
            if(e.target.value === element.id){
                formData.coach = element.id
                this.setState({ formData })
            }
        });
        this.setState({coachError: false})
    }

    setExotelAccount = (e) => {
        let { formData } = this.state
        formData.callingAccount = e.target.value
        this.setState({ formData })
        this.setState({exotelAccountError: false})
    }

    handleBack = () => {
        this.props.history.push("/projects")
        // this.props.history.push({
        //     pathname: `/projects`,
        //     state: {
        //         currentPage: this.props.location.state.currentPage
        //     }
        // })
        this.setState({ createProject: false })
    }

    setDnd = () => {

        let { formData } = this.state;
        let dnd = formData.isWhitelisted
        formData.isWhitelisted = !dnd;
        this.setState({ formData })


    }

    setDialout = () => {
        let { formData } = this.state;
        let isDialout = formData.isDialout
        formData.isDialout = !isDialout;
        this.setState({ formData })
    }

    setPauseHiring=()=>{
           let { formData } = this.state;
        let pauseHiring= formData.pauseHiring
        formData.pauseHiring = !pauseHiring;
        this.setState({ formData })
    }

    setEncryption = () => {
        let { formData } = this.state;
        let isEncrypted= formData.isEncrypted
        formData.isEncrypted = !isEncrypted;
        this.setState({ formData })
    }

    setAllocated = () => {
        let { formData } = this.state;
        let hasAllocatedDialing= formData.hasAllocatedDialing
        formData.hasAllocatedDialing = !hasAllocatedDialing
        this.setState({formData})
    }
    setPredictive = (e) => {
        let {formData} = this.state
        if(e){
            formData.dialer = 'predictive'
        }else{
            delete formData.dialer;
        }
        this.setState({formData})
    }
    setTrueCallerLabel = (e) => {
        const {truecallerIdentityLabelId} = this.state.poolData
        let { formData } = this.state
        if(truecallerIdentityLabelId == undefined){
            this.setState({trueCallerLabelError: true})
        }else{
            if(e == false){
                formData.isTruecallerCallReasonActive = e
            }else{
                formData.isTruecallerCallReasonActive = true
            }
            this.setState({formData})
        }
    }
    setIncomingCall = (e) => {
        let { formData } = this.state
        let isInboundCallEnabled = formData.isInboundCallEnabled
        formData.isInboundCallEnabled = !isInboundCallEnabled
        if(e == true){
            this.setState({isInboundCallToggleTouched: true})
        }else{
            this.setState({isInboundCallToggleTouched: false})
        }
        this.setState({ formData })
    }
    addFaq=()=>{
        let { formData } = this.state;
        let faqs = formData.faqs;
        faqs.push("");
        this.setState({ formData })
    }
      deletefaq=()=>{
        let { formData } = this.state;
        let faqs = formData.faqs;
        faqs.pop();
        this.setState({ formData })
    }
    addScript= () => {
        let { formData } = this.state;
        let scriptDocuments = formData.scriptDocuments;
        let dummyscriptDocuments = {

            title: "",
            url: ""
        }
        if(scriptDocuments)
        scriptDocuments.push(dummyscriptDocuments);
        else
          formData.scriptDocuments=[
                            {title:"",
                            url:""}
                        ]
        this.setState({ formData })
    }

    addLanguage = () => {
        let { formData } = this.state;
        let languages = formData.languages;
        let dummyscriptDocuments = {

            name: "",
            default: ""
        }
        if(languages)
        languages.push(dummyscriptDocuments);
        else
          formData.languages=[
                            {name:"",
                            default:""}
                        ]
        this.setState({ formData })
    }

    addAudio=() => {
        let { formData } = this.state;
        let audioFiles = formData.audioFiles;

        let dummyaudioFiles = {

            title: "",
            url: ""
        }
        if(audioFiles)
        audioFiles.push(dummyaudioFiles);
         else
          formData.audioFiles=[
                            {title:"",
                            url:""}
                        ]
        this.setState({ formData })
    }

    addTrainingVideo = () => {
        let { formData } = this.state;
        let trainingVideos = formData.trainingVideos;
        let dummyTrainingVideoFile = {
            title: "",
            url: ""
        }
        if(trainingVideos)
        trainingVideos.push(dummyTrainingVideoFile)
        else
        formData.trainingVideos = [{title: '', url: ''}]
        this.setState({ formData })
    }
    addTimeSlot = () => {
        let { formData } = this.state;
        let dummyTimeSlot = {
            startTime2: '9:00:00',
            endTime2: '21:00:00'  
        }
        formData.workTime = {
            ...formData.workTime,
            ...dummyTimeSlot
        }
        this.setState({formData})
    }
    removeSecondTimeSlot = () => {
        let { formData } = this.state;
        formData.workTime = {
            startTime: formData.workTime.startTime,
            endTime: formData.workTime.endTime
        }
        this.setState({formData})
    }
    addOutcome = () => {
        let { formData } = this.state;
        let outcomes = formData.outcomes;

        let dummyOutcome = {

            title: "",
            description: "",
            isWinning: false
        }

        outcomes.push(dummyOutcome);

        this.setState({ formData })
    }
    updateOutcome = (def, cus) => {
        let { formData } = this.state;
        let outcomes = [...def, ...cus]
        formData.outcomes = outcomes
        // this.setState({ formData })
    }

    outcomeScopeConfig = () => {
        this.setState({outcomeScope: this.state.outcomeScope + 1})
    }

    updateLeadDistribution = (newData, newOnwards, style = '') => {
        let { formData, updatedLeadDistributionData } = this.state;
        let temp = {}
      
        temp = {
            "style": style ? style: updatedLeadDistributionData.style
        }
        if(temp.style == 'cadence'){
            let obj = {}
            newData.map(item => {
                let minutes
                if(item.type == 'day'){
                    minutes = item.value * 24 * 60; // 1 day = 24 hours, 1 hour = 60 minutes
                }else if(item.type == 'hour'){
                    minutes = item.value * 60; // 1 hour = 60 minutes
                }else {
                    minutes = item.value
                }
                obj[item.number] = minutes
            })
            newOnwards.map(item => {
                let minutes
                if(item.type == 'day'){
                    minutes = item.value * 24 * 60; // 1 day = 24 hours, 1 hour = 60 minutes
                }else if(item.type == 'hour'){
                    minutes = item.value * 60; // 1 hour = 60 minutes
                }else {
                    minutes = item.value
                }
                obj["onwards"] = minutes
            })
            temp.cadence = obj
            this.setState({updatedLeadDistributionData: temp})
        }else{
            this.setState({updatedLeadDistributionData: {
                "style": temp.style
            }})
        }
    }
    audioField= (audio, i, formData) => {
        let audioArray = formData.audioFiles;
        return (
   <div className="script-field-wrapper">
                <div className="script-field">
                    {/* <ControlLabel> Title</ControlLabel> */}
                    <div  className="each">
                        <FormControl
                            required
                            type="text"
                            placeholder="Title"
                            name="title"
                            value={audio.title||"Mock Audio"}
                            onChange={(e) => this.setFormDataAudio(e, audio, i)}
                        />
                        {/* <ControlLabel> Description</ControlLabel> */}
    
                    </div>
                   <div  className="each">
                        <FormControl 
                            type="url"
                            placeholder="Url"
                            required
                            name="url"
                            value={audio.url}
                            onChange={(e) => this.setFormDataAudio(e, audio, i)}
                        />
                   </div>
                </div>
                   < FrappButton id="delete" className="add-btn" handler={() => this.deleteAudio(i, audioArray, formData)}> - Delete  </FrappButton>
   </div>
        )

    }
    videoField = (video, i, formData) => {
        let trainingVideoArray = formData.trainingVideos;
        return (
            <div className="script-field-wrapper">
                            <div className="script-field">
                            <div  className="each">
                                <FormControl
                                    // required
                                    type="text"
                                    placeholder="Enter training video title"
                                    name="title"
                                    value={video.title}
                                    onChange={(e) => this.setFormDataVideo(e, video, i)}
                                />
                            </div>
                            <div className="each">
                                    <FormControl 
                                        type="url"
                                        placeholder="Enter training video link"
                                        // required
                                        name="url"
                                        value={video.url}
                                        onChange={(e) => this.setFormDataVideo(e, video, i)}
                                    />
                            </div>
                            </div>
                   <FrappButton id="delete" disabled={trainingVideoArray.length == 1 ? true : false} className="add-btn" handler={() => this.deleteTrainingVideo(i, trainingVideoArray, formData)}> - Delete  </FrappButton>
            </div>
        )
    }
    scriptField = (script, i, formData) => {
        let scriptArray = formData.scriptDocuments;
        return (

<div className="script-field-wrapper">
    
                <div className="script-field">
                    {/* <ControlLabel> Title</ControlLabel> */}
                    <div className="each">
                        <FormControl
                            required
                            placeholder="Title"
                            type="text"
                            name="title"
                            value={script.title||"Script"}
                            onChange={(e) => this.setFormDataScript(e, script, i)}
                        />
                        {/* <ControlLabel> Description</ControlLabel> */}
    
                    </div>
                   <div className="each">
                        <FormControl 
                            placeholder="Url"
                            required
                            type="url"
                            name="url"
                            value={script.url}
                            onChange={(e) => this.setFormDataScript(e, script, i)}
                        />
                   </div>
             
                </div>
                           < FrappButton id="delete" className="add-btn" handler={() => this.deleteScript(i, scriptArray, formData)}> - Delete  </FrappButton>
</div>
        )

    }

    languageField = (lang, i, formData) => {
        let languageArray = formData.languages;
        if(languageArray.length == 1){
            languageArray[0].default = true
        }
        return (

            <div>
                
                            <div className="language-field">
                                {/* <ControlLabel> Title</ControlLabel> */}
                                <div className="language-wrapper">
                                <div className="each">
                                    <FormControl
                                        id="language"
                                        required
                                        placeholder="Enter language For Eg: English"
                                        type="text"
                                        name="lang"
                                        value={lang.name}
                                        onChange={(e) => this.setFormDataLanguage(e, lang, i)}
                                    />
                                    {/* <ControlLabel> Description</ControlLabel> */}
                
                                </div>
                                {/* <div className="cta-wrapper"> */}
                                <Checkbox className="checkbox" checked={lang.default} 
                                onChange={() => this.handleDefaultLang(lang.name, i)} 
                                style={{ color: "red" }}
                                ><h5>Default</h5></Checkbox>
                                </div>
                                < FrappButton id="delete" className="add-btn" 
                                disabled={languageArray.length == 1 ? true : false}
                                handler={() => this.deleteLanguage(i, languageArray, formData)}
                                > - Delete</FrappButton>
                                {/* </div> */}
                         
                            </div>
            </div>
                    )
    }

    outcomeField = (outcome, i, formData) => {
        let outcomeArray = formData.outcomes;
        return (


            <div>
                {/* <ControlLabel> Title</ControlLabel> */}
                <div>
                    <FormControl id="outcome"
                        required
                        className="outcome-t"
                        placeholder="Title"
                        name="title"
                        value={outcome.title}
                        onChange={(e) => this.setFormDataOutcome(e, outcome, i)}
                    />
                    {/* <ControlLabel> Description</ControlLabel> */}

                </div>
                <FormControl id="outcome"
                    className="outcome-d"
                    placeholder="Description"
                    required
                    name="description"
                    value={outcome.description}
                    onChange={(e) => this.setFormDataOutcome(e, outcome, i)}
                />


                <div className="cta-wrapper">
                    <Checkbox className="checkbox" checked={outcome.isWinning} onChange={() => this.handleCheckBox(outcome.isWinning, i)} style={{ color: "red" }}
                    ><h5>Set as Winning Outcome</h5></Checkbox>
                    < FrappButton id="delete" className="add-btn" handler={() => this.deleteOutcome(i, outcomeArray, formData)}> - Delete Outcome </FrappButton>
                </div>

            </div>
        )

    }

    deleteAudio = (index, audioArray, formData) => {
        audioArray.splice(index, 1);
        formData.audioFiles = audioArray;
        this.setState({ formData })
    }

    deleteScript=(index, scriptArray, formData) => {
        scriptArray.splice(index, 1);
        formData.scriptDocuments = scriptArray;
        this.setState({ formData })
    }
    deleteTrainingVideo = (index, trainingVideosArray, formData) => {
        trainingVideosArray.splice(index, 1);
        formData.trainingVideos = trainingVideosArray;
        this.setState({ formData })
    }
    deleteOutcome = (index, outcomeArray, formData) => {
        outcomeArray.splice(index, 1);
        formData.outcomes = outcomeArray;
        this.setState({ formData })
    }
    deleteLanguage = (index, languageArray, formData) => {
        languageArray.splice(index, 1)
        formData.languages = languageArray;
        this.setState({ formData })
    }
    setFormDataAudio= (e, audio, i) => {

        let { formData } = this.state;
        let changedAudio = audio;

        changedAudio[e.target.name] = e.target.value
        formData.audioFiles[i] = changedAudio
        this.setState({ formData })
    }
    setFormDataVideo = (e, video, i) => {
        let { formData } = this.state;
        let changedVideo = video
        changedVideo[e.target.name] = e.target.value
        formData.trainingVideos[i] = changedVideo
        this.setState({ formData })
    }
    setFormDataScript = (e, script, i) => {

        let { formData } = this.state;
        let changedScript= script;

        changedScript[e.target.name] = e.target.value
        formData.scriptDocuments[i] = changedScript
        this.setState({ formData })
    }
    setFormDataLanguage = (e, lang, i) => {
        let { formData } = this.state;
        formData.languages[i].name = capitalise(e.target.value.trim())
        formData.languages[i].default = false
        this.setState({ formData })
    }
    setFormDataOutcome = (e, outcome, i) => {

        let { formData } = this.state;
        let changedOutcome = outcome;
        changedOutcome[e.target.name] = e.target.value
        formData.outcomes[i] = changedOutcome
        if(e.target.name=="title"){
            this.setState({unpublishScript:true})
        }
        this.setState({ formData })
    }
    mainGoal = (mainGoal, i) => {
        let { formData } = this.state;
        return (

            <FormControl
                required
                placeholder="Enter main goal"
                name="mainGoal"
                value={mainGoal}
                onChange={(e) => this.setFormDataGoal(e, mainGoal, i)}
            />


        )
    }

    setFormDataGoal = (e, mainGoal, i) => {

        let { formData } = this.state;
        let changedGoal = mainGoal;

        changedGoal = e.target.value
        formData.mainGoal[i] = changedGoal
        this.setState({ formData })

    }

    validateForm = (e) => {
        let formData = this.state.formData;
        for (let member in formData) {
            if (formData[member] == null || formData[member].length == 0) {

                return false
            }
            else
                return true
        }
       
    }
    onBrandSelect = (brand) => {

        let { formData } = this.state;
        formData.brand = brand.id;
        formData.brandmanager = []
        ApiActions.getBrandManagerById(brand.id).then(resp => {
            this.setState({brandmanagerslist: resp})
        })

        this.setState({ formData, brandSelected: brand.id, bm: false, brandmanagers: [] })
        this.setState({brandError: false})


    }
    setFormDate = (date, end) => {

        let { formData } = this.state;
        if (!end) 
        {     date.utcOffset(0);
              date.set({hour:0,minute:0,second:0,millisecond:0})
              formData.startDate = date.toISOString();
        }
      
        if (end) 
        {    date.utcOffset(0);
             date.set({hour:23,minute:59,second:0,millisecond:0})
             formData.endDate = date.toISOString();
        }
        this.setState({ formData })
    }

    handleCheckBox = (isWinning, i) => {
        let { formData } = this.state;
        let changedValue = !isWinning;

        formData.outcomes[i].isWinning = changedValue
        this.setState({ formData })

    }

    handleDefaultLang = (name, i) => {
        let { formData } = this.state

        let result = formData.languages.map(lang => {
            if(lang.name == name)
            return {
                ...lang,
                default : true
            }
            else
            return{
                ...lang,
                default: false
            }
        })
        formData.languages = result
        this.setState({ formData })
    }

    checkTrainingVideosArray = (trainingVideos) => {
        let { formData } = this.state;
        let updatedTrainingVideosArray = trainingVideos.filter((obj, i) => {
            if(obj.title && obj.url){
                return obj
            }
        })
        if(updatedTrainingVideosArray.length <=0){
            formData.trainingVideos = updatedTrainingVideosArray;
            this.setState({formData})
        }else
        formData.trainingVideos = updatedTrainingVideosArray;
        this.setState({ formData })
    }

    submitForm = (e) => {
        e.preventDefault();
        let { formData } = this.state;
        let {unpublishScript}=this.state;
        let {sendForm}=this.state;
        let data = this.state;
        let createProject = this.state.createProject;
        let updateBtn = this.state.updateBtn;
        let checkExotelAccount = formData.callingAccount ? true : false
        if(!checkExotelAccount){
            this.setState({exotelAccountError: !checkExotelAccount})
            return true
        }
        let checkCoach = formData.coach && formData.coach ? true : false
        if(!checkCoach){
            this.setState({coachError: !checkCoach})
            return true
        }

        let checkTeamLead = formData.teamlead && formData.teamlead.length > 0 ? true : false
        if(!checkTeamLead){
            this.setState({teamLeadError: !checkTeamLead})
            return true
        }

        let checkProjectManager = formData.manager && formData.manager ? true : false
        if(!checkProjectManager){
            this.setState({projectManagerError: !checkProjectManager})
            return true
        }

        let checkBrandError = formData.brand && formData.brand ? false : true
        if(checkBrandError){
            this.setState({brandError: checkBrandError})
            return true
        }

        let checkBrandManagerError = formData.brandmanager && formData.brandmanager.length > 0 ?
        false : true
        if(checkBrandManagerError){
            this.setState({brandManagerError: checkBrandManagerError})
            return true
        }
        let checkCategorySubCategoryError = (!formData.category || !formData.subCategory) ? true : false
        if(checkCategorySubCategoryError){
            this.setState({categorySubCategoryError : checkCategorySubCategoryError})
            return true
        }
        if(this.state.isRewardAboveLimit){
            return true
        }

        this.checkTrainingVideosArray(formData.trainingVideos)
        let validate = this.validateForm();
        formData.leadDistribution = this.state.updatedLeadDistributionData
        if (createProject && !updateBtn && validate) {
            if (this.submitBtn) this.submitBtn.startLoading();
            ApiActions.postTeleProject(formData).then((resp) => {
                this.props.history.push({
                    // pathname: `/additional-data/${resp.data.id}`,
                    // pathname: `/add-tasks/${resp.data.id}`,
                    pathname: `/add-quiz/${resp.data.id}`,
                    state: {
                        formData,
                        data
                    }
                })
                if (this.submitBtn) this.submitBtn.stopLoading();
              toast("Done");
                this.setState({
                    dropdownSelected: false,
                    removedSelected: false,
                    feedbackExists: false,
                    teleproject: resp.data.id
                })

            }).catch(err => {
                toast(err.response && err.response.data && err.response.data.msg ? err.response.data.msg : 'Error')
                this.submitBtn.stopLoading();
            });
        }

        else if (updateBtn && validate) {

            if (this.submitBtn) this.submitBtn.startLoading();
            delete formData.updatedAt;
            delete formData.createdAt;
           
            ApiActions.updateTeleProject(formData, formData.id).then((resp) => {
                //  if(unpublishScript){
                // this.notifPopup.toggle(true)
                // ApiActions.getScript(formData.id).then(scr=>{
                //     let script = scr[0] && scr[0].script
                //     if(scr && scr[0])
                //     ApiActions.createScript({
		        // 		"teleproject": formData.id,
                //         "scriptPublished":false,
		        // 		"scriptId": scr[0].id,
                //         "script":script
		        // 	}).then(res=>{
                //         toast("Script Unpublished")
                //     })
                // })
                //  }

                this.props.history.push({
                    // pathname: `/additional-data/${resp.data.id}`,
                    // pathname: `/add-tasks/${resp.data.id}`,
                    pathname: `/add-quiz/${resp.data.id}`,
                    state: {
                        formData,
                        data
                    }
                })
                if (this.submitBtn) this.submitBtn.stopLoading();
                toast("Done");
                this.setState({
                    dropdownSelected: false,
                    removedSelected: false,
                    feedbackExists: false,
                    teleproject: resp.data.id
                })

            }).catch(err => {
                toast(err.response && err.response.data && err.response.data.msg ? err.response.data.msg : 'Error')
                this.submitBtn.stopLoading();
            })

        }
        else {
            toast("Contact Tech Team")
        }
    }
}


export default CreateTeleProject;
